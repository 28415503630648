<template>
	<div>
		<iframe id="H5" :src="iframeSrc" frameborder="0" name="H5" width="100%"></iframe>
	</div>
</template>

<script>

import {getuserConfig} from "@/api/user";
import {buryingPoint, getCurrent} from "@/api/prize";

export default {
	name: "prizeV1",
	data() {
		return {
			iframeSrc: ''
		}
	},
	created() {
    this.getCurrentActivity()
		this.subscribeToMessages()
		//  没有登录会自动跳转到登录
		localStorage.user ? getuserConfig() : this.$router.push("/loginRegistration?loginStep=1")
		this.login()
		let url = ''
		if (window.location.origin.indexOf("xht.com") > -1) {
			//测试
			url = 'http://eav-activity-web.xht.com'
		} else if (window.location.origin.indexOf("pre-zjeav.zjeav.com") > -1) {
			//预发
			url = 'http://pre-eav-activity-web.zjeav.com'
		} else if (window.location.origin.indexOf("zjeav.com") > -1) {
			//线上
			url = 'https://eav-activity-web.zjeav.com'
		} else if (window.location.origin.indexOf("localhost") > -1||window.location.origin.indexOf("172.") > -1) {
			// 开发
			// url = 'http://172.27.6.12:8080'
			url =	"http://localhost:8081"
		}
		if (localStorage.user) {
			const res = url + "?userId=" + JSON.parse(localStorage.user)?.userId + "&token=" + JSON.parse(localStorage.user)?.token + '&t=' + new Date().getTime() + '&userName=' + JSON.parse(localStorage.user)?.userName + '&type=WEB'
      let activityCode = this.$route.query.activityCode || ''
      this.iframeSrc  = res + (activityCode? `&activityCode=${activityCode}`:'')
    } else {
			this.iframeSrc = url + '?t=' + new Date().getTime() + '&type=WEB';
		}
		// console.log(this.iframeSrc)
	},
	methods: {
    async getCurrentActivity(){
      let activityCode = this.$route.query.activityCode || ''
      if(!activityCode){
        const res = await getCurrent()
        activityCode = res.data.activityCode
      }
      localStorage.setItem('activityCode', activityCode)
    },
		// 登录埋点
		async login() {
      if(!localStorage.user) return
			const res = await buryingPoint({
				activityCode:  localStorage.getItem('activityCode')||'E_STUDY',
				actionCode: 'LOGIN',
				userId: JSON.parse(localStorage.user)?.userId
			});
		},
		subscribeToMessages() {
			//event.data :传递过来的信息，也就是 postMessage 中发出的 message；
			//event.origin: 发送信息页面的域名，包括协议和端口号。
			const that = this
			window.addEventListener("message", function (event) {
				// 子页面通信数据
				// console.log(event.data.path)
				// console.log(111111111,event.data)
				if (event.data?.path) {
					that.$router.push(event.data.path)
				}
			});
		}
	}
}
</script>

<style scoped>
#H5 {
	/*margin-top: -16px;*/
	min-height: calc(100vh - 72px);
}
</style>
